import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React, { useMemo } from "react";
import { Select as DesignSystemSelect } from "../../index";
import { useReadOnly } from "@/components/DesignSystem/Forms/ReadOnlyContext";

const { Field } = Forms;

export const Select = ({ disabled: disabledProp, ...props }) => {
    const disabled = useReadOnly({ disabled: disabledProp });

    return (
        <Field
            as={DesignSystemSelect}
            from={identity}
            showSearch
            disabled={disabled}
            {...props}
        />
    );
};

// waiting for UC fix, then it can be removed
// onDeselect in UC Select calls onChange with empty array when no props.options are passed (freetext select in mode="tags")
export const createOptions_tempFixDeselect = value =>
    (value || []).map(value => ({ value, label: value }));

export const Select_tempFixDeselect = props => {
    const value = Forms.useFieldValue({ name: props.name });
    const options = useMemo(
        () => createOptions_tempFixDeselect(value),
        [value],
    );
    // console.log("[Sfix.rndr]", { name: props.name, value, options });

    return <Select {...props} options={options} />;
};
