import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React from "react";
import { DatePicker as DesignSystemDatePicker } from "../../index";
import { useReadOnly } from "@/components/DesignSystem/Forms/ReadOnlyContext";

const { Field } = Forms;

export const DatePicker = ({ disabled: disabledProp, ...props }) => {
    const disabled = useReadOnly({ disabled: disabledProp });

    return (
        <Field
            as={DesignSystemDatePicker}
            from={identity}
            disabled={disabled}
            {...props}
        />
    );
};
