import { createContext } from "react";
import React from "react";

export const ReadOnlyContext = createContext(undefined);

export const ReadOnlyProvider = ({ children, readOnly }) => {
    return (
        <ReadOnlyContext.Provider value={readOnly}>
            {children}
        </ReadOnlyContext.Provider>
    );
};

export const useReadOnly = ({ disabled: disabledProp }) => {
    const ctxValue = React.useContext(ReadOnlyContext);
    return disabledProp ?? ctxValue;
};
