import { useReadOnly } from "@/components/DesignSystem/Forms/ReadOnlyContext";
import { Tooltip } from "@/components/DesignSystem/Tooltip";
import { Checkbox as UnityCheckbox } from "@pricefx/unity-components";
import React from "react";

const withTooltip = (element, tooltip) => {
    return (
        <Tooltip tooltip={tooltip}>
            <span>{element}</span>
        </Tooltip>
    );
};

// https://pricefx.atlassian.net/browse/PFUN-24882
export function Checkbox({ tooltip, disabled: disabledProp, ...props }) {
    const disabled = useReadOnly({ disabled: disabledProp });

    return tooltip ? (
        withTooltip(<UnityCheckbox disabled={disabled} {...props} />, tooltip)
    ) : (
        <UnityCheckbox disabled={disabled} {...props} />
    );
}

Checkbox.Group = UnityCheckbox.Group;
