import { Forms } from "@pricefx/unity-components";
import React from "react";
import { Input as DesignSystemInput } from "../../index";
import { useReadOnly } from "@/components/DesignSystem/Forms/ReadOnlyContext";

const { Field } = Forms;

export const Input = ({ disabled: disabledProp, ...props }) => {
    const disabled = useReadOnly({ disabled: disabledProp });

    return <Field as={DesignSystemInput} disabled={disabled} {...props} />;
};

Input.getLoadableProps = loadableOrResource => {
    const loadable = loadableOrResource?.loadable || loadableOrResource;

    switch (loadable.state) {
        case "loading":
            return {
                disabled: true,
                placeholder: "Loading...",
            };
        case "hasValue":
            return {};
        case "hasError":
            return {
                disabled: true,
                placeholder: "Error",
            };
    }
};
