import { Forms } from "@pricefx/unity-components";
import identity from "lodash/identity";
import React from "react";
import { InputNumber as DesignSystemInputNumber } from "../../index";
import { useReadOnly } from "@/components/DesignSystem/Forms/ReadOnlyContext";

const { Field } = Forms;

export const InputNumber = ({ disabled: disabledProp, ...props }) => {
    const disabled = useReadOnly({ disabled: disabledProp });

    return (
        <Field
            from={identity}
            as={DesignSystemInputNumber}
            disabled={disabled}
            {...props}
        />
    );
};
