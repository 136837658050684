// TODO: FIXME: Need this polyfill beacuse of passing auth headers, until we accept cookies auth
import { durationPrecisely } from "@/components/DateTime/helpers/durationPrecisely";
import { useDic } from "@/components/Dic/useDic.hook";
import { LOGIN_TOKEN_NAME } from "@/security/security";
import { isProduction } from "@/utils/env.utils";
import { fetchEventSource } from "@microsoft/fetch-event-source";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";

const initialState = {
    eventSource: null,
};

export const SSEContext = React.createContext(initialState);

const getSSEEndpoint = () =>
    isProduction()
        ? "https://platform-manager-be-eu-west-1.plprod.pricefx.com/api/sse"
        : "https://platform-manager-be-eu-west-1.plqa.pricefx.com/api/sse";

const connectSSE = async ({
    abortRef,
    headers,
    onOpen,
    onError,
    onMessage,
}) => {
    try {
        await fetchEventSource(getSSEEndpoint(), {
            headers,
            signal: abortRef.current.signal,
            onmessage: onMessage,
            onopen: onOpen,
            onerror: onError,
            openWhenHidden: true,
        });
    } catch (error) {
        console.info("SSE connection error", error);
    }
};

const useExponentialCounter = (initialNumber = 0) => {
    const counter = useRef(initialNumber);
    const base = useRef(2);

    const getNextValue = () => {
        const value = Math.pow(base.current, counter.current);
        counter.current += 1;
        return value;
    };

    const reset = () => {
        counter.ref = initialNumber;
    };

    return { reset, getNextValue };
};

const SSEContextProvider = ({ children }) => {
    const [state, setState] = useState({
        reconnectTry: 1,
        isConnected: false,
        isConnecting: false,
    });
    const { authenticationService } = useDic();
    const abortRef = useRef(new AbortController());
    const eventSourceRef = useRef(new EventTarget());

    const exponentialCounter = useExponentialCounter(2);

    const getHeaderWithAuth = useCallback(async () => {
        if (localStorage.getItem(LOGIN_TOKEN_NAME) != null) {
            return {
                [LOGIN_TOKEN_NAME]: localStorage.getItem(LOGIN_TOKEN_NAME),
            };
        } else {
            return {
                Authorization: `Bearer ${authenticationService.getToken()}`,
            };
        }
    }, [authenticationService]);

    const connectSSEAsync = useCallback(async () => {
        const isLoggedIn = authenticationService.isLoggedIn();
        if (authenticationService.isAccessTokenExpired()) {
            await authenticationService.refreshToken();
        }

        if (!state.isConnected && !state.isConnecting && isLoggedIn) {
            return connectSSE({
                abortRef,
                onMessage: ev => {
                    eventSourceRef.current.dispatchEvent(
                        new CustomEvent(ev.event, { detail: ev }),
                    );
                },
                headers: await getHeaderWithAuth(),
                onOpen: () => {
                    exponentialCounter.reset();
                    setState({
                        isConnecting: false,
                        isConnected: true,
                        reconnectTry: 1,
                    });
                },
                onError: e => {
                    const nextValue = exponentialCounter.getNextValue();
                    console.log("SSE error", e);
                    console.info(
                        `Trying to reconnect SSE at ${new Date(
                            Date.now() + nextValue * 1000,
                        ).toISOString()}. After ${durationPrecisely({
                            value: moment.duration(nextValue, "seconds"),
                            locale: "en",
                        })}`,
                    );
                    setTimeout(() => {
                        setState(s => ({
                            reconnectTry: s.reconnectTry + 1,
                            isConnected: false,
                            isConnecting: true,
                        }));
                        connectSSEAsync();
                    }, nextValue * 1000);

                    // stops local library reconnection
                    throw e;
                },
            });
        } else {
            return Promise.resolve();
        }
    }, [
        authenticationService,
        exponentialCounter,
        getHeaderWithAuth,
        state.isConnected,
        state.isConnecting,
    ]);

    useEffect(() => {
        connectSSEAsync();
    }, []);

    return (
        <SSEContext.Provider value={{ eventSource: eventSourceRef.current }}>
            {children}
        </SSEContext.Provider>
    );
};

SSEContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};

export default SSEContextProvider;
