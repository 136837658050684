import { Forms } from "@pricefx/unity-components";
import React from "react";
import { Input as DesignSystemInput } from "../../index";
import { useReadOnly } from "@/components/DesignSystem/Forms/ReadOnlyContext";

const { Field } = Forms;

export const TextArea = ({ disabled: disabledProp, ...props }) => {
    const disabled = useReadOnly({ disabled: disabledProp });

    return (
        <Field as={DesignSystemInput.TextArea} disabled={disabled} {...props} />
    );
};
