import {
    ButtonMenu,
    Forms,
    Modal,
    UnityLayout,
} from "@/components/DesignSystem";
import { fieldTypes } from "@/components/DesignSystem/Table/constants";
import { useSetValidatedInitialValues } from "@/components/hooks/useSetValidatedInitialValues.hook";
import { t } from "@/translations";
import React, { useMemo } from "react";
import { Gap } from "../Gap";

const getComponent = fieldType =>
    ({
        [fieldTypes.TEXT]: Forms.Fields.Input,
        [fieldTypes.OPTION]: Forms.Fields.Select,
    }[fieldType]);

const createUniqueValidator =
    (property, otherRows) =>
    (value = []) =>
        otherRows.some(item => item[property] === value)
            ? Forms.error("Must be unique")
            : Forms.success();

export const EntityModal = ({
    visible,
    onClose,
    columns = [],
    isEdit,
    initialValues,
    onSubmit,
    valueWithIds,
    editRecordId,
    disabled,
    modalExtraProps: { title = "", description = "", submitButtonLabel } = {},
}) => {
    const { formId, Form, setValues, setTouched } = Forms.useForm({
        onSubmit: ({ values }) => onSubmit(values),
    });
    useSetValidatedInitialValues({ initialValues, setValues, setTouched }, [
        initialValues,
    ]);

    const otherRows = useMemo(() => {
        if (!editRecordId) return valueWithIds;
        else return valueWithIds.filter(item => item._id !== editRecordId);
    }, [editRecordId, valueWithIds]);
    const fields = useMemo(
        () =>
            columns.map(
                ({
                    fieldProps: { required, unique, ...fieldProps } = {},
                    ...col
                }) => ({
                    name: col.name,
                    label: col.label,
                    options: col.options,
                    Component: getComponent(col.type),
                    required: !!required,
                    validator: Forms.validators.failOnFirst(
                        []
                            .concat(
                                required ? Forms.pmValidators.isRequired : [],
                            )
                            .concat(
                                unique
                                    ? createUniqueValidator(col.name, otherRows)
                                    : [],
                            ),
                    ),
                    disabled,
                    ...fieldProps,
                }),
            ),
        [columns, disabled, otherRows],
    );

    return (
        <Modal visible={visible} onClose={onClose}>
            <UnityLayout>
                <UnityLayout.Header size={3} title={title} />
                <UnityLayout.Content padding={[false, true]}>
                    {description && (
                        <>
                            {description}
                            <Gap />
                        </>
                    )}
                    <Form disableBanner>
                        {fields.map(({ Component, ...props }) => (
                            <Component key={props.name} {...props} />
                        ))}
                    </Form>
                </UnityLayout.Content>
                <UnityLayout.Footer
                    actionButtons={
                        <ButtonMenu
                            buttons={[
                                {
                                    visible: !disabled,
                                    label:
                                        submitButtonLabel ||
                                        t(
                                            isEdit
                                                ? "general.save"
                                                : "general.add",
                                        ),
                                    type: "primary",
                                    htmlType: "submit",
                                    formId,
                                },
                                {
                                    label: t("general.cancel"),
                                    onClick: onClose,
                                    type: "text",
                                },
                            ]}
                        />
                    }
                />
            </UnityLayout>
        </Modal>
    );
};

EntityModal.propTypes = {};
