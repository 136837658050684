import { UnitySwitch } from "@/components/DesignSystem";
import cx from "classnames";
import React from "react";
import "./Switch.style.less";
import { useReadOnly } from "@/components/DesignSystem/Forms/ReadOnlyContext";

export function Switch({ className, disabled: disabledProp, ...props }) {
    const disabled = useReadOnly({ disabled: disabledProp });

    return (
        <UnitySwitch className={cx(className)} disabled={disabled} {...props} />
    );
}
