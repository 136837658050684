import { Button as UnityButton } from "@pricefx/unity-components";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { useTrackButtonClick } from "../../../mixpanel/hooks/useTrackButtonClick.hook";
import { Forms } from "@/components/DesignSystem/Forms";

const ClickWrapper = ({ onClick, children }) => (
    <span onClick={onClick}>{children}</span>
);

// Tracking not working - onClick passed to SubmitButton changes htmlType in UC
export const SubmitButtonTempFix = ({ onClick, ...props }) => {
    return (
        <ClickWrapper onClick={onClick}>
            <Forms.SubmitButton {...props} />
        </ClickWrapper>
    );
};

export const types = ["default", "primary", "link", "text"];
export function Button({
    className,
    icon,
    type,
    label,
    onClick, // assuming submit() is called in onClick
    track,
    visible = true,
    formId, // pass to enable submitting state
    htmlType,
    Component = formId && htmlType === "submit" && !onClick // onClickTracked changes htmlType, blocking submit (but works when submit() called in onClick)
        ? SubmitButtonTempFix
        : formId
        ? Forms.SubmitButton
        : UnityButton,
    ...props
}) {
    const classes = cx("pmButton", className);
    const { trackHandler } = useTrackButtonClick();

    if (!visible) return null;

    const onClickTracked = trackHandler(onClick, {
        defaultName: label || props.tooltip || icon?.name,
        ...track,
    });

    const element = (
        <Component
            formId={formId}
            className={classes}
            icon={icon}
            type={type}
            htmlType={htmlType}
            label={label}
            onClick={onClickTracked}
            {...props}
        />
    );

    return element;
}

Button.propTypes = {
    className: PropTypes.string,
    label: PropTypes.node,
    formId: PropTypes.any,
    visible: PropTypes.bool,
    Component: PropTypes.any,
    // accepts imported svg only, see unity components docs
    icon: PropTypes.any,
    type: PropTypes.oneOf(types),
    onClick: PropTypes.func,
    track: PropTypes.oneOfType([
        PropTypes.shape({ name: PropTypes.any }),
        PropTypes.bool,
    ]),
};
