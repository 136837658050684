import { Forms } from "@pricefx/unity-components";
import PropTypes from "prop-types";
import React from "react";
import { Checkbox as DesignSystemCheckbox } from "../../index";
const { Field } = Forms;

export const Checkbox = ({ onClick, label, disabled, tooltip, ...props }) => {
    return (
        <Field
            valueProp="value"
            from={({ value }) => value}
            disabled={disabled}
            tooltip={tooltip}
            {...props}
        >
            <DesignSystemCheckbox
                onClick={onClick}
                label={label}
                tooltip={tooltip}
                disabled={disabled}
            />
        </Field>
    );
};
Checkbox.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    tooltip: PropTypes.string,
};

export const InvertedCheckbox = props => (
    <Checkbox from={({ value }) => !value} to={value => !value} {...props} />
);
