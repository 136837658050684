import { t } from "@/translations";
import { ReactComponent as Copy } from "@pricefx/unity-components/src/icons/unicons/copy.svg";
import PropTypes from "prop-types";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button } from "../Button";
import { Input } from "../Input";
import styles from "./TextAreaWithCopy.styles.less";

export function TextAreaWithCopy({ value, ...props }) {
    return (
        <>
            <CopyToClipboard text={value}>
                <Button
                    type="text"
                    size="small"
                    className={styles.copyButtonContainer}
                    icon={Copy}
                    label={t("general.copy")}
                />
            </CopyToClipboard>
            <Input.TextArea value={value} {...props} />
        </>
    );
}

TextAreaWithCopy.propTypes = {
    value: PropTypes.string,
};
