import { UnityIcon } from "@/components/DesignSystem";
import { ReactComponent as ExternalLink } from "@pricefx/unity-components/src/icons/unicons/external-link-alt.svg";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";
import "./Link.styles.less";
export const DISPLAY_ICON = {
    never: "never",
    always: "always",
    hover: "hover",
};

export function Link({
    children,
    targetBlank = false,
    displayIcon = DISPLAY_ICON.never,
    icon = <UnityIcon component={ExternalLink} />,
    className,
    ...props
}) {
    const renderIcon = [DISPLAY_ICON.always, DISPLAY_ICON.hover].includes(
        displayIcon,
    );
    const classNames = cx(
        className,
        displayIcon === "hover" && "pm-external-link-hover-icon",
    );

    return (
        <a
            {...props}
            className={classNames}
            target={targetBlank ? "_blank" : undefined}
            rel="noopener noreferer"
        >
            {children}
            {renderIcon ? icon : null}
        </a>
    );
}

Link.propTypes = {
    children: PropTypes.node.isRequired,
    href: PropTypes.string.isRequired,
    className: PropTypes.string,
    targetBlank: PropTypes.bool,
    icon: PropTypes.node,
    displayIcon: PropTypes.oneOf(Object.values(DISPLAY_ICON)),
};
